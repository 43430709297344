/* eslint-disable */
import * as bcrypt from 'bcryptjs';

import { AppError, JWT, JwtTypes } from '@voyage-lab/core-common';
import type { EmailService } from '@voyage-lab/core-email';
import type { DatabaseEntity, PostgrestClientType } from '@voyage-lab/db';
import type { TypesT } from '@voyage-lab/schema';
import { Helpers } from '@voyage-lab/util';

const CREDENTIAL_PROVIDERS = ['credentials'];
const ACCESS_TOKEN_EXPIRE_MINUTES = 60 * 24 * 7; // 7 days
const REFRESH_TOKEN_EXPIRE_MINUTES = 60 * 24 * 30; // 30 days
const RECOVERY_TOKEN_EXPIRE_MINUTES = 15; // 15 minutes

export class AuthData {
	#dbClient: PostgrestClientType;
	#secret: string;
	email?: EmailService;
	#jwt: JWT;

	constructor({
		dbClient,
		email,
		jwtSecret: secret,
	}: {
		dbClient: PostgrestClientType;
		jwtSecret: string;
		email?: EmailService;
		host?: string;
	}) {
		this.#dbClient = dbClient;
		this.#secret = secret;
		this.email = email;
		this.#jwt = new JWT(this.#secret);
	}

	async login(props: LoginProps) {
		const isPasswordLessProvider = !CREDENTIAL_PROVIDERS.includes(props.provider || '');

		const userQuery = this.#dbClient.from('users').select(
			`
				password,
				role,
				is_staff,
				id,
				email,
				phone,
				family_name,
				given_name,
				extra_data,
				user_permissions(
					state, 
					permissions(
						id, 
						action, 
						attribute
					)
				),
				tenants(
					id,
					brands(
						id,
						subscriptions(
							plans(
								plan_permissions(
									state, 
									permissions(
										id, 
										action, 
										attribute
									)
								)
							)
						),
						brand_permissions(
							state,
							permissions(
								id, 
								action, 
								attribute
							)
						)
					),
					tenant_permissions(
						state, 
						permissions(
							id, 
							action, 
							attribute
						)
					)
				)
			`
		);
		userQuery.eq('tenants.brands.brand_permissions.state', 'permitted');
		userQuery.in('tenants.brands.subscriptions.status', ['active', 'in_trial']);
		userQuery.limit(1, { foreignTable: 'tenants.brands.subscriptions' });
		if (props.id) userQuery.eq('id', props.id);
		if (props.email) userQuery.eq('email', props.email);

		const userRes = await userQuery;

		if (userRes.data?.length === 0) {
			console.debug('auth.login', 'user not found');
			throw new Error('Email or password is incorrect!');
		}

		const userData = isPasswordLessProvider
			? userRes?.data?.[0]
			: userRes?.data?.find(
					(u) => props.password && u.password && bcrypt.compareSync(props.password, u.password)
				);

		if (props.app === 'admin' && userData?.is_staff === false)
			throw new Error('You are not authorized to access this app!');

		if (!isPasswordLessProvider) {
			if (!props.password) throw new Error('Password not provided!');
			if (userRes?.data?.every((u) => !u.password))
				throw new Error('Password is not setup, reset your password to login!');

			const isPasswordValid = bcrypt.compareSync(props.password, userData?.password || '');
			if (!isPasswordValid) throw new Error('Invalid password!');
		}

		const userPermissions = userData?.user_permissions || [];
		const tenantPermissions = userData?.tenants?.tenant_permissions || [];
		const brandPermissions = userData?.tenants?.brands?.flatMap(({ brand_permissions }) => brand_permissions) || [];
		const planPermissions =
			userData?.tenants?.brands?.flatMap(({ subscriptions }) =>
				subscriptions.flatMap(({ plans }) => plans?.plan_permissions || [])
			) || [];

		const pol = this.#createPolicy({
			permissions: [userPermissions, planPermissions, brandPermissions, tenantPermissions].flat(2),
		});

		if (!userData?.tenants?.id) throw new Error('Tenant not found');
		if (!userData) throw new Error('Username or password is incorrect!');
		if (!userData.tenants) throw new Error('Tenant not found!');

		const bid = userData.tenants?.brands?.[0]?.id;
		const role = ((userData?.is_staff ? 'staff_' : 'brand_') + userData.role) as TokenEntity['role'];

		const jwt: Omit<TokenEntity, 'iat' | 'exp' | 'typ'> = {
			bid,
			pol,
			role,
			sub: userData.id,
			tid: userData.tenants.id,
		};

		const accessToken = this.#jwt.encode({ ...jwt, typ: 'at' }, `${ACCESS_TOKEN_EXPIRE_MINUTES}m`);
		const refreshToken = this.#jwt.encode({ ...jwt, typ: 'rt' }, `${REFRESH_TOKEN_EXPIRE_MINUTES}m`);

		return {
			refreshToken: refreshToken,
			token: accessToken,
			userId: userData.id,
			email: userData.email,
			familyName: userData.family_name,
			givenName: userData.given_name,
			brandId: bid,
			tenantId: userData.tenants.id,
			phone: userData.phone,
			pictureUrl: userData?.extra_data?.picture_url,
			isStaff: userData.is_staff,
			role: userData.role,
		};
	}

	#createPolicy(props: {
		permissions: (Partial<DatabaseEntity['user_permissions']> & {
			permissions: Partial<DatabaseEntity['permissions']> | null;
		})[];
	}) {
		const uniquePermissions = Helpers.Array.unique(props.permissions, (p) => p.permissions?.id);
		const allowedPermissions = Object.values(uniquePermissions)
			.filter(({ state }) => state === 'permitted')
			.flatMap(({ permissions }) => permissions);

		// Remove permissions if wildcard attribute of the same action exists
		const minimizedPermissions = allowedPermissions.filter(
			(p) =>
				p?.attribute?.includes('beta') ||
				p?.attribute === '*' ||
				!allowedPermissions.some((p2) => p2?.action === p?.action && p2?.attribute === '*')
		);

		const permissionNames = minimizedPermissions.map((p) => `${p!.action}:${p!.attribute}` as TokenPolicy);

		return permissionNames;
	}

	updateAccessToken(props: UpdateTokenProps) {
		const tokenEntity = this.#jwt.decode<TokenEntity>(props.token);
		if (!tokenEntity) throw new AppError('Invalid token', 'UnauthenticatedError');

		//Remove existing exp property on decoded jwt
		// @ts-expect-error: Not sure why this was needed
		delete tokenEntity.exp;

		const token = this.#jwt.encode(
			{
				...tokenEntity,
				...props.data,
			},
			`${ACCESS_TOKEN_EXPIRE_MINUTES}m`
		);

		return {
			token,
		};
	}

	async refreshAuth({ refreshToken }: { refreshToken: string }) {
		const tokenEntity = this.#jwt.decode<TokenEntity>(refreshToken);
		if (!tokenEntity?.sub) throw new AppError('Invalid refresh token', 'UnauthenticatedError');

		const auth = await this.login({ id: tokenEntity.sub });

		return auth;
	}

	async forgetPassword(props: { email: string; host: string }) {
		const email = props.email;
		const user = await this.#dbClient.from('users').select('email, auth').eq('email', email).limit(1).maybeSingle();
		const userAuthData = (user.data?.auth as TypesT.UserJson['auth']) || {};

		if (!user.data) {
			return null;
		}

		const recoveryToken = this.#jwt.encode<JwtTypes['token']>(
			{
				type: 'recovery',
				email,
			},
			`${RECOVERY_TOKEN_EXPIRE_MINUTES}m`
		);

		await this.#dbClient
			.from('users')
			.update({
				auth: {
					...userAuthData,
					recovery_token: recoveryToken,
				},
			})
			.eq('email', email);

		const setupLink = `${props.host}?token=${recoveryToken}`;
		const content = `You have requested for password recover! You can set up your new password by clicking the link below, the link is valid for ${RECOVERY_TOKEN_EXPIRE_MINUTES} minutes. <br /> <a href="${setupLink}">Set up new password</a>`;

		if (!this.email) throw new Error('Email service not available');
		return this.email.sendEmail({
			content: content,
			subject: 'Reset your LiveRecover password',
			recipient: email,
		});
	}

	async resetPassword(props: { token: string; password: string; confirmPassword: string }) {
		const token = props.token;
		const password = props.password;
		const confirmPassword = props.confirmPassword;

		if (password !== confirmPassword) {
			throw new Error('Passwords do not match');
		}

		if (!token) {
			throw new Error('Invalid token');
		}

		const decodedToken = this.#jwt.decode<JwtTypes['token']>(token);

		if (!decodedToken) {
			throw new Error('Invalid token');
		}

		const userRes = await this.#dbClient
			.from('users')
			.select('auth')
			.eq('email', decodedToken.email)
			.limit(1)
			.maybeSingle();

		const user = userRes.data as DatabaseEntity['users'] & TypesT.UserJson;

		if (!user) {
			console.debug('auth.login', 'user not found');
			throw new Error('Username or password is incorrect!');
		}

		if (!user?.auth?.recovery_token) {
			throw new Error('Invalid token');
		}

		const hashedPassword = this.hashPassword(password);

		const authData = user.auth as TypesT.UserJson['auth'];
		if (authData) delete authData.recovery_token;

		return this.#dbClient
			.from('users')
			.update({
				password: hashedPassword,
				auth: {
					...authData,
				},
			})
			.eq('email', decodedToken.email);
	}

	async addWebauthnPasskey(props: { userId: string; passkey: any }) {
		const userRes = await this.#dbClient.from('users').select('auth').eq('id', props.userId).limit(1).maybeSingle();

		if (!userRes.data) throw new Error('User not found');
		if (!userRes.data) throw new Error('User auth data not found');

		const newCredentials = userRes.data.auth?.webauthn?.pass_keys || [];
		newCredentials.push(props.passkey);

		return this.#dbClient
			.from('users')
			.update({
				auth: {
					...userRes.data.auth,
					webauthn: {
						...(userRes.data.auth?.webauthn || {}),
						pass_keys: newCredentials,
					},
				},
			})
			.eq('id', props.userId)
			.select('auth')
			.maybeSingle();
	}

	async deleteWebauthnPasskey(props: { userId: string; id: string }) {
		const userId = props.userId;
		const id = props.id;

		const userRes = await this.#dbClient.from('users').select('auth').eq('id', userId).limit(1).maybeSingle();

		if (!userRes.data) {
			throw new Error('User not found');
		}

		const newCredentials = userRes.data.auth?.webauthn?.pass_keys?.filter((c) => c.id !== id) || [];

		return this.#dbClient
			.from('users')
			.update({
				auth: {
					...userRes.data.auth,
					webauthn: {
						pass_keys: newCredentials,
					},
				},
			})
			.eq('id', userId)
			.select('auth')
			.maybeSingle();
	}

	async getAuthData(props: { userId?: string; email?: string }) {
		const userId = props.userId;

		const userQuery = this.#dbClient.from('users').select('auth');

		if (userId) {
			userQuery.eq('id', userId);
		}
		if (props.email) {
			userQuery.eq('email', props.email);
		}
		if (!userId && !props.email) {
			throw new Error('User id or email not provided');
		}

		const userRes = await userQuery.limit(1).maybeSingle();

		if (!userRes.data) {
			throw new Error('User not found');
		}

		return userRes.data.auth;
	}

	async setupAccount(props: {
		token: string;
		given_name: string;
		family_name: string;
		password: string;
		confirmPassword: string;
	}) {
		const token = props.token;
		const givenName = props.given_name;
		const familyName = props.family_name;
		const password = props.password;
		const confirmPassword = props.confirmPassword;

		if (password !== confirmPassword) {
			throw new Error('Passwords do not match');
		}

		if (!token) {
			throw new Error('Invalid token');
		}

		const decodedToken = this.#jwt.decode<JwtTypes['token']>(token);

		if (!decodedToken) {
			throw new Error('Invalid token');
		}

		const userRes = await this.#dbClient
			.from('users')
			.select('auth, extra_data')
			.eq('email', decodedToken.email)
			.limit(1)
			.maybeSingle();

		const user = userRes.data as DatabaseEntity['users'] & TypesT.UserJson;

		if (!user) {
			throw new Error('User not found');
		}

		if (user?.auth?.setup_token !== token) {
			throw new Error('Invalid token');
		}

		const authData = user.auth as TypesT.UserJson['auth'];
		if (authData) delete authData.setup_token;

		const extra_data = user.extra_data as TypesT.UserJson['extra_data'];
		if (extra_data) delete extra_data.invite_expires_at;

		const hashedPassword = this.hashPassword(password);

		return this.#dbClient
			.from('users')
			.update({
				given_name: givenName,
				family_name: familyName,
				password: hashedPassword,
				auth: authData,
				state: 'active',
				extra_data: extra_data,
			})
			.eq('email', decodedToken.email);
	}

	hashPassword(password: string) {
		const hashedPassword = bcrypt.hashSync(password, 10);
		return hashedPassword;
	}

	comparePassword(password: string, hashedPassword: string) {
		const isPasswordValid = bcrypt.compareSync(password, hashedPassword);
		return isPasswordValid;
	}
}

type PaymentProvider = 'shopify' | 'stripe';

export type LoginProps = {
	email?: string;
	password?: string;
	passKey?: string;
	provider?: 'google' | 'credentials' | 'shopify' | 'bigcommerce';
	app?: 'admin' | 'client';
	id?: string;
};

export type UpdateTokenProps = {
	token: any;
	data: Object;
};

export type LoginResponse = Awaited<ReturnType<typeof AuthData.prototype.login>> & {
	shopify_access_token?: string;
	id?: string;
};

export type BetaFetures = '*' | 'client-dashboard' | 'post-purchase' | 'bigcommerce' | 'tap-to-text';
export type TokenPolicyAction = 'create' | 'read' | 'update' | 'delete' | 'list' | '*';
type TokenPolicyResource = keyof DatabaseEntity;
type TokenPolicyAttribute = keyof DatabaseEntity['workflows'] | keyof DatabaseEntity['discount_rules'] | '*';
export type TokenPolicy =
	| `${TokenPolicyAction}:${TokenPolicyResource}.${TokenPolicyAttribute}`
	| `${TokenPolicyAction}:${TokenPolicyResource}`
	| `${TokenPolicyAction}:beta:${BetaFetures}`;

export interface TokenEntity {
	/** Issued At: used by Cube Cloud and Postgrest to determine when the token was issued */
	iat: number;

	/** Expiration: used by Cube Cloud and Postgrest to determine when the token expires */
	exp: number;

	/** User ID */
	sub: string;

	/** Token Type: rt, at, it, rt (refresh, access, invitation, reset)
	 * This is used to determine what the token is for */
	typ: 'rt' | 'at' | 'it' | 'rt';

	/** User's Role this is used by Cube Cloud and Postgrest as well as the frontend to determine what the user can do */
	role: 'brand_admin' | 'brand_member' | 'staff_admin' | 'staff_member';

	/** Policy CSV, list of policies/features that the user has access to */
	pol: TokenPolicy[];

	/** Brand ID */
	bid: string;

	/** Tenant ID */
	tid: string;

	/** Impersonated Tenant ID */
	itid?: string;
}
