import { CubeUtils, type GraphqlQueryTypes } from '@voyage-lab/cube';
import { Helpers } from '@voyage-lab/util';

export type WorkflowStatsType = {
	description: string;
	value: number | string;
	percentage: number;
};

export const generateStats = (data?: GraphqlQueryTypes.GetWorkflowStatsQuery) => {
	const events = data?.events.filter((e) => e.workflow_goal_state_change_events.state_display === 'Recovered');
	const currConversationMessages = data?.curr_conversation_messages[0];
	const prevConversationMessages = data?.prev_conversation_messages[0];
	const currOrder = data?.curr_order[0];
	const prevOrder = data?.prev_order[0];

	const charts = {
		x: events?.map((e) => e.workflow_goal_state_change_events.created_at).filter((e) => !!e) || [],
		y: events?.map((e) => e.workflow_goal_state_change_events.count || 0) || [],
	};

	const stats = [
		{
			description: 'Sent',
			value: Helpers.Number.formatToString(
				currConversationMessages?.conversation_messages.sent_message_count || 0
			),
			percentage: Helpers.Number.calcIncrementPercentage(
				prevConversationMessages?.conversation_messages.sent_message_count || 0,
				currConversationMessages?.conversation_messages.sent_message_count || 0
			),
		},
		{
			description: 'Replied',
			value: Helpers.Number.formatToString(currConversationMessages?.conversation_messages.reply_count || 0),
			percentage: Helpers.Number.calcIncrementPercentage(
				prevConversationMessages?.conversation_messages.reply_count || 0,
				currConversationMessages?.conversation_messages.reply_count || 0
			),
		},
		{
			description: 'Ordered',
			value: Helpers.Number.formatToString(currOrder?.orders.recovered_count || 0),
			percentage: Helpers.Number.calcIncrementPercentage(
				prevOrder?.orders.recovered_count || 0,
				currOrder?.orders.recovered_count || 0
			),
		},
		{
			description: 'Sales',
			value: '$' + Helpers.Number.abbreviateNumber(currOrder?.orders.recovered_sales || 0),
			percentage: Helpers.Number.calcIncrementPercentage(
				prevOrder?.orders.recovered_sales || 0,
				currOrder?.orders.recovered_sales || 0
			),
		},
	];

	return { stats, charts };
};
