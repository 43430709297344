import type { PostgrestClientType } from '@voyage-lab/db';

export class PermissionData {
	#dbClient: PostgrestClientType;

	constructor(dbClient: PostgrestClientType) {
		this.#dbClient = dbClient;
	}

	async getAllPermissions(props: { brandId: string; filter?: 'beta' }) {
		const query = this.#dbClient
			.from('permissions')
			.select('*, brand_permissions(*)')
			.eq('brand_permissions.brand_id', props.brandId)
			.eq('brand_permissions.state', 'permitted');

		if (props.filter === 'beta') query.ilike('attribute', '%beta:%');

		const { data } = await query;

		// eslint-disable-next-line @typescript-eslint/naming-convention
		return data?.map(({ brand_permissions, ...permission }) => ({
			...permission,
			enabled: brand_permissions.some((bp) => bp.state === 'permitted'),
		}));
	}
}
